





















































import api from "@/api";
import {Component, Vue} from "vue-property-decorator";
import {ServiceOrganizationDto} from "@/api/appService";
import ProvinceCityAreaView, {
  ProvinceCityAreaTownDto,
} from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaView.vue";
import ProvinceCityAreaContent from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaContent.vue";

@Component({
  name: "serviceOrganizationDtoDetail",
  components: {ProvinceCityAreaView, ProvinceCityAreaContent},
  filters: {},
})
export default class ServiceObjectDetail extends Vue {
  editServiceOrganizationId?: number;
  detail: ServiceOrganizationDto = {};
  loading = true;

  foundationArea: ProvinceCityAreaTownDto = {
    provinceId: 0,
    cityId: 0,
    areaId: 0,
  };

  created() {
    if (this.$route.params.id) {
      this.editServiceOrganizationId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.serviceOrganization
      .get({id: this.editServiceOrganizationId})
      .then((res) => {
        this.loading = false;
        this.detail = {...res};
        this.foundationArea = {
          provinceId: res!.provinceId ?? 0,
          cityId: res!.cityId ?? 0,
          areaId: res!.areaId ?? 0,
        };
        console.log(res);
      });
    console.log(this.detail);
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
